
.gallery {
  aspect-ratio: 283 / 168;
}

@media screen(desktop) {
  .gallery:hover .navigation {
    display: flex;
  }
}
